import styled from "./styled-components";
import { COLOR } from "./constants";

export const StyledChip = styled.div`
  display: inline-block;
  padding: 0 25px;
  height: 40px;
  font-size: 1.6rem;
  line-height: 40px;
  border-radius: 25px;
  background-color: #f1f1f1;
  margin-right: 1.3rem;
  margin-bottom: 1.3rem;

  color: ${COLOR.VDEP_SECONDARY_BLUE};
`;
