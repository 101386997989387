import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import Layout from "../components/layout";
import Header from "../components/header";
import { UpcomingEvents, PageType } from "../components/UpcomingEvents";
import SEO from "../components/seo";
import { filterEvents } from "../lib/filterEvents";
import { AboveTheFold, BrowserWindow } from "../styles/Main";
import { Story } from "../components/Story";
import { Services } from "../components/Services";
import { OrganisationCTA } from "../components/OrganisationCTA";
import { Seminars } from "../components/Seminars";
import { Experience } from "../components/Experience";
import PositionsTable from "../components/PricingTable";
import { UnderConstruction } from "../components/UnderConstruction";
import { MissionStatement } from "../components/MissionStatement";
import { About } from "../components/About";

const IndexPage = ({ data }) => {
  const language = "en-gb";

  return (
    <Layout lang={language} cookie_consent={data.cookie_consent}>
      <AboveTheFold>
        <Header header={data.header} language={language} />
        <React.Fragment>
          <SEO
            title={data.seo.data.website_title.text}
            keywords={data.seo.data.keywords.map(row => row.keyword)}
            description={data.seo.data.description.text}
            lang={"nl-nl"}
          />
        </React.Fragment>
      </AboveTheFold>
      <Services services={data.services} />
      <PositionsTable functies={data.functies} tekst={data.vraagAanbodTekst} />
      <MissionStatement missionStatement={data.missionStatement} />
      <Story story={data.story} language={language} />
      <About about={data.about} />
    </Layout>
  );
};

export const query = graphql`
  query {
    seo: prismicSeo {
      data {
        website_title {
          text
        }
        description {
          text
        }
        keywords {
          keyword
        }
      }
    }
    cookie_consent: prismicCookieConsent {
      data {
        text
      }
    }
    header: prismicHeader {
      data {
        background_image {
          url
          dimensions {
            width
            height
          }
        }
      }
    }

    services: prismicServices {
      data {
        title {
          text
        }
        body {
          html
        }
        soorten_functies {
          functie
        }
      }
    }

    story: prismicStory {
      data {
        title {
          text
        }
        body {
          html
        }
      }
    }

    missionStatement: prismicMissionStatement {
      data {
        image {
          url
        }
        statement {
          html
        }
      }
    }

    about: prismicAbout {
      data {
        image {
          url
        }
        title {
          html
          text
        }
        body {
          html
        }
        cv_link_text
        cv_link {
          url
          target
        }
      }
    }
    vraagAanbodTekst: prismicVraagaanbodBlockTekst {
      data {
        body {
          html
        }
        title {
          text
        }
      }
    }
  }
`;

export default IndexPage;
