import * as React from "react";
import ReactHtmlParser from "react-html-parser";
import { Expertise as StyledExpertise, FunctieChips } from "../styles/Main";
import { StyledChip } from "../styles/chip";

export const Services = ({ services }) => {
  return (
    <StyledExpertise>
      <div>
        <h2>{services.data.title.text}</h2>
        {ReactHtmlParser(services.data.body.html)}
        <FunctieChips>
          {services.data.soorten_functies.map(func => (
            <StyledChip>{func.functie}</StyledChip>
          ))}
        </FunctieChips>
      </div>
    </StyledExpertise>
  );
};
