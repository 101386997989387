import * as React from "react";
import ReactHtmlParser from "react-html-parser";
import Grid from "@material-ui/core/Grid";
import Check from "@material-ui/icons/Check";
import withWidth from "@material-ui/core/withWidth";
import { translate as t } from "../lib/i18n";
import {
  Benefits as StyledBenefits,
  CenteredParagraph,
  BenefitsOptions,
  TableContainer,
  Container,
  LegendItem,
  LegendItemMediumLeft,
  LegendItemMarginBottom,
  LegendItemLargeLeft,
  ColumnItemLargeRight,
  ColumnItem,
  ColumnItemMedium,
  ColumnItemLarge,
  ColumnItemMarginBottom,
  CheckItem,
  ColumnTitleLeft,
  ColumnTitleRight,
  ItemEmpty,
  ChoiceItem,
  NoteItem,
  InterestedItem,
  LegendItemMedium,
  Row,
  RowHeading,
} from "../styles/PricingTable";
import { Checkbox } from "./Checkbox";
import { Inquiry } from "./Inquiry";

interface Functie {
  data: {
    referentie: string;
    functie_titel: string;
    vraag_of_aanbod: string;
  };
}

class PositionsTable extends React.Component<
  {
    functies: {
      nodes: Functie[];
    };
    tekst: any;
  },
  { selected: string }
> {
  state = {
    selected: "organisation",
  };

  select = (type: string) => {
    this.setState({ selected: type });
  };

  render() {
    // const { benefits, benefitsOptions, width, language } = this.props;
    // const optionOrg = benefitsOptions.nodes.find(
    //   option => option.uid === "organisation"
    // );
    // const optionInd = benefitsOptions.nodes.find(
    //   option => option.uid === "individual"
    // );
    const { tekst } = this.props;
    return (
      <StyledBenefits>
        <h2>{tekst.data.title.text}</h2>
        <CenteredParagraph>
          {ReactHtmlParser(tekst.data.body.html)}
        </CenteredParagraph>
        {/* <BenefitsOptions>
          <TableContainer>
            <Container>
              <RowHeading>
                <ItemEmpty>&nbsp;</ItemEmpty>
                <ColumnTitleLeft>VRAAG</ColumnTitleLeft>
                <ColumnTitleRight>AANBOD</ColumnTitleRight>
              </RowHeading>
              {functies.nodes.map(functie => (
                <Row>
                  <LegendItem>
                    <p>{functie.data.referentie}</p>
                  </LegendItem>
                  <ColumnItemMedium>
                    <p>
                      {functie.data.vraag_of_aanbod === "vraag" &&
                        functie.data.functie_titel}
                    </p>
                  </ColumnItemMedium>
                  <ColumnItemMedium>
                    <p>
                      {functie.data.vraag_of_aanbod === "aanbod" &&
                        functie.data.functie_titel}
                    </p>
                  </ColumnItemMedium>
                </Row>
              ))}
            </Container>
          </TableContainer>
        </BenefitsOptions> */}
      </StyledBenefits>
    );
  }
}

export default withWidth()(PositionsTable);
