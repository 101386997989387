import * as React from "react";
import ReactHtmlParser from "react-html-parser";

import {
  MissionStatementContainer as StyledMissionStatementContainer,
  MissionStatement as StyledMissionStatement,
  MissionText,
  MissionImageContainer,
  AboutImageContainer,
} from "../styles/Main";
import { Button } from "../styles/buttons";
import { TitleThick } from "../styles/Header";

export const About = ({ about }) => {
  return (
    <StyledMissionStatementContainer>
      <StyledMissionStatement>
        <MissionText>
          {ReactHtmlParser(about.data.title.html)}
          {ReactHtmlParser(about.data.body.html)}
          <a href={about.data.cv_link.url} target="_blank">
            <Button>{about.data.cv_link_text}</Button>
          </a>
        </MissionText>
        <AboutImageContainer>
          <img src={about.data.image.url} />
        </AboutImageContainer>
      </StyledMissionStatement>
    </StyledMissionStatementContainer>
  );
};
