import * as React from "react";
import ReactHtmlParser from "react-html-parser";

import {
  MissionStatementContainer as StyledMissionStatementContainer,
  MissionStatement as StyledMissionStatement,
  MissionText,
  MissionImageContainer,
} from "../styles/Main";

export const MissionStatement = ({ missionStatement }) => {
  return (
    <StyledMissionStatementContainer>
      <StyledMissionStatement>
        <MissionText>
          {ReactHtmlParser(missionStatement.data.statement.html)}
        </MissionText>
        <MissionImageContainer>
          <img src={missionStatement.data.image.url} />
        </MissionImageContainer>
      </StyledMissionStatement>
    </StyledMissionStatementContainer>
  );
};
