import * as React from "react";
import ReactHtmlParser from "react-html-parser";
import { Story as StyledStory, StoryBody } from "../styles/Main";

export const Story = ({ story, language }) => {
  return (
    <StyledStory>
      <h2>{story.data.title.text}</h2>
      <StoryBody>{ReactHtmlParser(story.data.body.html)}</StoryBody>
    </StyledStory>
  );
};
