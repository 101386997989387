import React from "react";

import { translate as t } from "../lib/i18n";

import Image from "./image";
import {
  Header as StyledHeader,
  Hero,
  SiteTitleContainer,
  LogoContainer,
  CoG,
  LogoTextOne,
  LogoTextTwo,
  LogoTextThree,
  SitePayoff,
} from "../styles/Header";

const Header = ({ header, language }) => (
  <StyledHeader>
    <Hero heroImgUrl={header.data.background_image.url}>
      <SiteTitleContainer>
        <LogoContainer>
          <h1>
            <LogoTextOne>OPP services</LogoTextOne>
            <LogoTextTwo>Offshore Projects Personnel</LogoTextTwo>
          </h1>
        </LogoContainer>
        <SitePayoff>
          {/* <h2>specialiseert in Offshore Project Functies</h2> */}
          {/* <h3>{header.data.cog_sub_message.text}</h3> */}
          {/* site tekst later from prismic */}
        </SitePayoff>
      </SiteTitleContainer>
    </Hero>
  </StyledHeader>
);

export default Header;
